import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { PropiedadService } from "../../servicios/propiedad.service";

@Component({
  selector: 'app-inmueble',
  templateUrl: './inmueble.component.html',
  styleUrls: ['./inmueble.component.css']
})
export class InmuebleComponent implements OnInit {

  propiedad:any ={

  };
  imgPropiedad:any ={};
  imagenes:any[]=[];
  index:number;
  imgOn:string;
  imgInicio:boolean = false;
  

  constructor(private _activateRoute:ActivatedRoute,
              private _propiedadService:PropiedadService) {
              this._activateRoute.params.subscribe(data =>{
                  console.log(data['id']);
                  this.propiedad = this._propiedadService.getPropiedad(data['id']);
                  console.log("Data" + data);
                  
                  this.imgPropiedad = this.propiedad.display;
                  this.imagenes = this.imgPropiedad.display; 
                  console.log("Imagenes" + this.imagenes);
                  console.log("-PRPIEDAD---" + this.imgPropiedad[1].img);
                  this.index = this.imgPropiedad.index;
                  console.log("index" + this.index);
    });
   }

  ngOnInit(): void { 
  
  }

                getIndex(termino:string){
                  let imagenPropiedades:any[] = [];

                  for(let property of this.imgPropiedad){
                   
                   
                    imagenPropiedades.push(property);
                  }
                  //return imagenPropiedades;
                  this.imgInicio = true;
                  this.imgOn = imagenPropiedades[termino].image;
                  console.log(imagenPropiedades[termino]);
                }
                getIndex2(termino:string){
                 console.log(termino);
                  
                }

                getImagen(){

                }
               

}
