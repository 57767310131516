<nav class="navbar navbar-expand-md navbar-light bg-light float-nav" style="">
    <a class="navbar-brand text-center" href="#/">
        <img src="assets/img/navbar/realestate-ico.png" class="d-inline-block align-top" alt="Real Estate by Infocabos" loading="lazy"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <article class="navegacion text-center">
            <h3>Hello</h3> Welcome to Los Cabos Real Estate
            <div class="txt-center">
                <div class="fb-like" data-href="https://realestate.infocabos.com/#/home" data-width="" data-layout="button" data-action="like" data-size="small" data-share="true"></div>
            </div>
        </article>
        <ul class="navbar-nav mr-auto">
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="home">Home </a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="land">Land</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="condos">Condos</a>
            </li>

        </ul>

    </div>
</nav>