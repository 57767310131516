import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";

import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { CondosComponent } from './components/condos/condos.component';
import { LandComponent } from './components/land/land.component';
import { SearchComponent } from './components/search/search.component';

//rutas
import { ROUTES } from './app.routes';
import { TarjetasComponent } from './components/tarjetas/tarjetas.component';
import { DevelopmentsComponent } from './components/developments/developments.component';
import { FootComponent } from './components/sheared/foot/foot.component';
import { InmuebleComponent } from './components/inmueble/inmueble.component';

//Servicios
import { PropiedadService  } from "./servicios/propiedad.service";
import { DomseguroPipe } from './pipes/domseguro.pipe';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    CondosComponent,
    LandComponent,
    SearchComponent,
    TarjetasComponent,
    DomseguroPipe,
    DevelopmentsComponent,
    FootComponent,
    InmuebleComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, {useHash: true}),
    AppRoutingModule
  ],
  providers: [
    //aquí van los servicios
    PropiedadService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
