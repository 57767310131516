<main class="mb-2">

    <div class="container contenedor-principal ">

        <h1>Lands</h1>

        <div class="card-group mt-1 mb-5">
            <div class="card" *ngFor="let lands of lands;let i = index">
                <img [src]="lands.image" class="card-img-top" [alt]="lands.propiedad" [routerLink]="['/inmueble',i]">
                <div class="card-body">
                    <h5 class="card-title">{{lands.propiedad}}</h5>
                    <p class="card-text">{{lands.info}}</p>
                </div>
                <div class="card-footer">
                    <small class="text-muted">Last updated september 2020</small>
                </div>

            </div>
        </div>

    </div>
</main>