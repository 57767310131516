import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';


@Pipe({
  name: 'domseguro'
})
export class DomseguroPipe implements PipeTransform {

  constructor( private domSanitizer:DomSanitizer ){ }

 /* transform( value: string, url: string): any {
    return this.domSanitizer.bypassSecurityTrustResourceUrl( url + value );
  }
  */
 transform( value: string): any {
  const url = "https://www.google.com/maps/embed?pb=";
  return this.domSanitizer.bypassSecurityTrustResourceUrl( url + value );
}

}
