import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { PropiedadService } from "../../servicios/propiedad.service";

@Component({
  selector: 'app-land',
  templateUrl: './land.component.html',
  styleUrls: ['./land.component.css']
})
export class LandComponent implements OnInit {
  propiedad:any =[];
  lands:any[]=[];
  land:any={};
  tipo = 0;
  tierras:any[]=[];
  constructor(private _landsServices:PropiedadService) { 
    
    }

  ngOnInit(): void {
    this.propiedad  = this._landsServices.getPropiedades();

    for(let i of this.propiedad){
      if(i.tipo == 0){
        
        console.log("uno " + i.propiedad);

        this.land = i;
        this.lands.push(i);
        console.log("dos " + this.lands);
        this.tipo++;
      }
    }
      
      
      
   
  }

}
