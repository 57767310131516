<div class="section">

</div>
<div class="card-group mt-1 mb-5">
    <div class="card" *ngFor="let propiedad of propiedades;let i = index">
        <img [src]="propiedad.image" class="card-img-top" [alt]="propiedad.propiedad" [routerLink]="['/inmueble',i]">
        <div class="card-body">
            <h5 class="card-title">{{propiedad.propiedad}}</h5>
            <p class="card-text">{{propiedad.info}}</p>
        </div>
        <div class="card-footer">
            <small class="text-muted">Last updated september 2020</small>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col">
                <!-- Con más de 712029 habitantes -->
            </div>
            <div class="col">


            </div>
        </div>
        <div>
            <div class="whats" id="cambio"> <a href="https://api.whatsapp.com/send?phone=5216241222286&text=Hola%2C%20deseo%20comunicarme%20con%20ustedes" target="_blank"><i class="fab fa-whatsapp"></i></a></div>

        </div>