import { Injectable } from '@angular/core';

@Injectable()
export class PropiedadService{

    private propiedad:any[] = [
        {
            propiedad: "Tourist-Residential Zone | El Tezal Land.",
            id_index:0,
            tipo:0,
            info: "With nearly: 1312.34 ft. Beautiful view of the Sea, the Bay of Cabo San Lucas. located in El Tezal near Costco",
            image:"assets/img/tarjetasInicio/tarjetas1.jpg",
            detalles:"",
            uri:"!1m18!1m12!1m3!1d18336.41942650486!2d-109.89318040884551!3d22.915583421988963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86af4b21d73a849f%3A0xc5514919a60e0f66!2sVilla%20Finca%20Tezal!5e1!3m2!1ses-419!2smx!4v1598929986624!5m2!1ses-419!2smx",
            precio:"450 000",
            display:[
                { img:"assets/img/inmuebles/tezal/nav_inmueble.png",
                  index: 0,
                  image:"assets/img/tarjetasInicio/tarjetas1.jpg"
                 },
                { img:"assets/img/inmuebles/tezal/nav_inmueble1.png",
                index: 1,
                image:"assets/img/inmuebles/tezal/tezal1.jpg"
                 },
                {img:"assets/img/inmuebles/tezal/nav_inmueble2.png",
                index: 2,
                image:"assets/img/inmuebles/tezal/tezal2.jpg"
                 },
                {img:"assets/img/inmuebles/tezal/nav_inmueble3.png",
                index: 3,
                image:"assets/img/inmuebles/tezal/tezal3.jpg"
                },
                { img:"assets/img/inmuebles/tezal/nav_inmueble4.png",
                index: 4,
                image:"assets/img/inmuebles/tezal/tezal4.jpg"
                },
                {img:"assets/img/inmuebles/tezal/nav_inmueble5.png",
                index: 5,
                image:"assets/img/inmuebles/tezal/tezal5.jpg"
               }
            ]

           
        },
        {
            propiedad: "Santa Barbara Residential | Cabo San Lucas, México.",
            id_index:1,
            tipo:1,
            info: "In residential segment, lot size  220 m2, house size 132.88 m2, stage 3, 24 hour security.",
            image:"assets/img/tarjetasInicio/tarjetas3.jpg",
            detalles:"",
            uri : "!1m18!1m12!1m3!1d3675.179939647528!2d-109.94838748452156!3d22.9067329850119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86af4b3939602d53%3A0x9e843dde73891363!2sResidencial%20Santa%20B%C3%A1rbara!5e0!3m2!1ses-419!2smx!4v1598929549880!5m2!1ses-419!2smx",
           precio:"2,290,000",
            display:[
                { img:"assets/img/inmuebles/santabarbara/santabarbara1.png",
                index: 0,
                image:"assets/img/inmuebles/santabarbara/santabarbara1.jpg"
               },
              { img:"assets/img/inmuebles/santabarbara/santabarbara2.png",
              index: 1,
              image:"assets/img/inmuebles/santabarbara/santabarbara2.jpg"
              },
              {img:"assets/img/inmuebles/santabarbara/santabarbara3.png",
              index: 2,
              image:"assets/img/inmuebles/santabarbara/santabarbara3.jpg"
              },
              {img:"assets/img/inmuebles/santabarbara/santabarbara4.png",
              index: 3,
              image:"assets/img/inmuebles/santabarbara/santabarbara4.jpg"
               },
              { img:"assets/img/inmuebles/santabarbara/santabarbara5.png",
              index: 4,
              image:"assets/img/inmuebles/santabarbara/santabarbara5.jpg"
              },
              {img:"assets/img/inmuebles/santabarbara/santabarbara6.png",
              index: 5,
              image:"assets/img/inmuebles/santabarbara/santabarbara6.jpg"
             }
            ]

        },
        {
            propiedad: "Villa Dorada | Cabo San Lucas, México.",
            id_index:2,
            tipo:1,
            info: "Furnished apartment in ideal opportunity for ideal business for AirBnB.",
            image:"assets/img/tarjetasInicio/tarjetas2.jpg",
            precio:"1,850,000",
            detalles: `🌴Construction of 96 m2 | Terrace and pool use | 2 Bedrooms with Closets | Integral climate system | FULLY FURNISHED
            `,
            uri: "!1m18!1m12!1m3!1d21811.651601109144!2d-109.89563028116798!3d22.879187868472663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86af4ae001353823%3A0xf03eea565f57fdc5!2sVilla%20Dorada%2C%20Av%20Solmar%2C%20Marina%2C%20Cabo%20San%20Lucas%2C%20Baja%20California%20Sur!5e1!3m2!1ses-419!2smx!4v1598923402015!5m2!1ses-419!2smx",
            display:[
               
                { img:"assets/img/inmuebles/villadorada/villadorada.jpg",
                index: 0,
                image:"assets/img/inmuebles/villadorada/villadorada.jpg"
                },
              { img:"assets/img/inmuebles/villadorada/villadorada2.jpg",
              index: 1,
              image:"assets/img/inmuebles/villadorada/villadorada2.jpg"
                },
                    {img:"assets/img/inmuebles/villadorada/villadorada3.jpg",
                    index: 2,
                    image:"assets/img/inmuebles/villadorada/villadorada3.jpg"
                },
                    {img:"assets/img/inmuebles/villadorada/villadorada4.jpg",
                    index: 3,
                    image:"assets/img/inmuebles/villadorada/villadorada4.jpg"
                },
                    { img:"assets/img/inmuebles/villadorada/villadorada5.jpg",
                    index: 4,
                    image:"assets/img/inmuebles/villadorada/villadorada5.jpg"
                },
                    {img:"assets/img/inmuebles/villadorada/villadorada6.jpg",
                    index: 5,
                    image:"assets/img/inmuebles/villadorada/villadorada6.jpg"
                }
                
            ]

        }

    ]


    constructor(){
        console.log("servicio listo para usar");
    }

    getPropiedades(){
        return this.propiedad;
    }
    getPropiedad(idx:string){
        return this.propiedad[idx];
    }
    images:string[] =[];
    getImages(){
        this.images = this.propiedad;
        
        return this.images;
    }

    buscarImagen(termino:string){
        
        let propertyArr:any[]=[];
        //termino =
       // for(let i of this.)
    }
    tierras:any[]=[];
    tipo = "0";
    getLands(){
       
        for(let tierra of this.propiedad){
            console.log(tierra.tipo);
            if(tierra.tipo == this.tipo){
              this.tierras.push(tierra);
            }
        return  this.tierras;
       
    }
   }
   condos:any[]=[];
    tipo2:"1";
   getCondos(){
   
    for(let i = 0; i<= this.propiedad.length; i++){
        console.log(this.propiedad[i]);
        this.condos.push(this.propiedad);
        
    return  this.condos;
   
}
}
condosObject = {};condominios:any[] = [];
getCondominios(){
    let tipo = 1;
    for(let i = 0; i< this.propiedad.length; i++){
        console.log(this.propiedad.length);
        this.condosObject = this.propiedad[i];
        console.log(this.propiedad[i].tipo);
        if(tipo == this.propiedad[i].tipo){
            this.condominios.push(this.condosObject);
        }
        console.log("Objetos ::: " + this.condosObject);
        
        
   
   
}
     return  this.condominios;
    
}
   
    
}


