import { Routes } from "@angular/router";
import { HomeComponent } from './components/home/home.component';
import { CondosComponent } from './components/condos/condos.component';
import { LandComponent } from './components/land/land.component';
import { SearchComponent } from './components/search/search.component';
import { InmuebleComponent } from './components/inmueble/inmueble.component';

export const ROUTES:Routes =[
    
      {  path: 'home', component:HomeComponent },
      {  path: 'condos', component:CondosComponent},
      {  path: 'land', component:LandComponent},
      {  path: 'search', component:SearchComponent},
      {  path: 'inmueble/:id', component:InmuebleComponent},
      {  path: '', pathMatch:'full', redirectTo:'home'},
      {  path: '**', pathMatch:'full', redirectTo:'home'}

   

];