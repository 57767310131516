<main class="mb-2">

    <div class="container contenedor-principal">

        <h1>Condos</h1>

        <div class="card-group mt-1 mb-5">
            <div class="card" *ngFor="let propiedad of lands;let i = index">
                <img *ngIf="propiedad.tipo == 1" [src]="propiedad.image" class="card-img-top" [alt]="propiedad.propiedad" [routerLink]="['/inmueble',propiedad.id_index]">
                <div class="card-body">
                    <h5 class="card-title" *ngIf="propiedad.tipo == 1">{{propiedad.propiedad}}</h5>
                    <p class="card-text" *ngIf="propiedad.tipo == 1">{{propiedad.info}}</p>
                </div>
                <div class="card-footer" *ngIf="propiedad.tipo == 1">
                    <small class="text-muted" *ngIf="propiedad.tipo == 1">Last updated september 2020</small>
                </div>

            </div>
        </div>

    </div>
</main>