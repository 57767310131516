<div id="carouselExampleCaptions" class="carousel slide mt-3" data-ride="carousel">
    <ol class="carousel-indicators">
        <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
        <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="./assets/img/carrusel/carrusel1.jpg" class="d-block w-100" alt="Tezal">
            <div class="carousel-caption d-none d-md-block" style="background-color: rgba(0, 0, 0, 0.13);">
                <h5>Tezal - Land 400 meters</h5>
                <p>Beautiful view of the Sea, the Bay of Cabo San Lucas.</p>
            </div>
        </div>
        <div class="carousel-item">
            <img src="./assets/img/carrusel/carrusel2.jpg" class="d-block w-100" alt="Altamira Plus">
            <div class="carousel-caption d-none d-md-block">
                <h5>Altamira Plus</h5>
                <p>Condos in one of the best local areas of Cabo San Lucas</p>
            </div>
        </div>
        <div class="carousel-item">
            <img src="./assets/img/carrusel/carrusel3.jpg" class="d-block w-100" alt="Allteza">
            <div class="carousel-caption d-none d-md-block">
                <h5>Altezza</h5>
                <p>Residential with concrete streets, goodwill assured.</p>
            </div>
        </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>
<div class="container mt-3">
    <h3>Featured Listings</h3>
</div>

<app-tarjetas></app-tarjetas>
<div class="mb-5">
    We are a young company focused on offering the best of Cabo San Lucas, and its surroundings either for a rest property, to live or to use for a business development
</div>