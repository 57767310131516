import { Component } from '@angular/core';

@Component({
  selector: 'app-foot',
  templateUrl: './foot.component.html',
  styleUrls: ['./foot.component.css']
})
export class FootComponent  {
  
  fecha:Date = new Date();
  constructor() { 
  this.fecha ;
  }

 

}
