import { Component, OnInit } from '@angular/core';
import { PropiedadService } from "../../servicios/propiedad.service";

@Component({
  selector: 'app-tarjetas',
  templateUrl: './tarjetas.component.html',
  styleUrls: ['./tarjetas.component.css']
})
export class TarjetasComponent implements OnInit {

  inmueble:any[] = [{
    name : "Casa1",
    link : "#/inmueble"
  }];

  propiedades:any[] = [];

  constructor(private _propiedadService:PropiedadService) { 
    this.inmueble;
    console.log(this.inmueble);
  }

  ngOnInit(): void {
  this.propiedades = this._propiedadService.getPropiedades();
  console.log(this.propiedades);
  }

}
