<main class="mb-2">

    <div class="container contenedor-principal ">
        <div class="img-main">


            <img *ngIf="imgInicio == false" [src]="propiedad.image" alt="" style="max-width: 100%;">
            <img *ngIf="imgInicio == true" [src]="imgOn" alt="" style="max-width: 100%;">
        </div>

        <div class="smallNav">
            <div class="container menu-img txt-center">
                <ul class="nav nav-img txt-center">
                    <li class="nav-item" *ngFor="let img of imgPropiedad, let i = index">
                        <img [src]="img.img" alt="{{propiedad.propiedad}}" (click)="getIndex(i)" #buscartxt>
                        <!-- <a class="nav-link active" href="#">{{img.img}}</a>-->
                        <span><i class="fas fa-arrow-alt-circle-right"></i></span>
                    </li>

                </ul>
            </div>
        </div>

        <section class="ml-img-3 ">
            <div class="d-flex flex-column">
                <h1>{{propiedad.propiedad}}</h1>
                <p>
                    {{propiedad.info}} <br> {{propiedad.detalles}} </p>

                <span>Cost: <i class="fas fa-dollar-sign"></i> {{propiedad.precio}}  </span>(price are in Mexican Pesos)
            </div>

        </section>

    </div>

</main>
<hr class="separacion">
<div class="container menu-img txt-center smallNav1">
    <ul class="nav nav-img txt-center">
        <li class="nav-item" *ngFor="let img of imgPropiedad, let i = index">
            <img [src]="img.img" alt="{{propiedad.propiedad}}" (click)="getIndex(i)" #buscartxt>
            <!-- <a class="nav-link active" href="#">{{img.img}}</a>-->
            <span><i class="fas fa-arrow-alt-circle-right"></i></span>
        </li>

    </ul>
</div>
<hr class="separacion2">
<div class="container contenedor ">
    <div class="perfil">
        <div class="banner-izq">


            <img src="./assets/img/asesor/img_asesor.png" alt="Property Asesor" />
            <section>


                <h5>José Benitéz</h5>
                <p>
                    Real estate advisor, I am here to support you and offer you the best of opportunities in the purchase of real estate.
                    <span>
                         <a href="https://api.whatsapp.com/send?phone=5216241222286&text=Hola%2C%20deseo%20comunicarme%20con%20ustedes%20:{{propiedad.propiedad}}" target="_blank">
                            <i class="fab fa-whatsapp"></i></a>
                            <a href="http://m.me/reloscabosbyinfocabos" target="_blank">
                                <i class="fab fa-facebook-messenger"></i></a>
                        </span>

                </p>
            </section>
        </div>

        <iframe [src]="propiedad.uri | domseguro" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

    </div>
    <section class="banner-der">
        <div class="d-flex flex-column">
            <h5>{{propiedad.propiedad}}</h5>
            <p>
                {{propiedad.info}} <br> {{propiedad.detalles}} </p>

            informacion relevante de la propiedad:
        </div>
    </section>


</div>